import _filterBuilder from "./_filterBuilder";
import axiosInstance from "~/shared/_axios";

// LOGO importing
import soleLogo from "Images/sole-white.svg";

function render() {
  const id = "footbed-finder-modal-popup";
  const el = document.querySelector("#" + id);
  const start = {
    _scene: "start",
    _styleClass: {
      content: "text-center text-white FF__blue",
      body: "align-items-center justify-content-center"
    },
    _hideControl: true,
    logo: {
      src: soleLogo,
      alt: "Sole.",
      width: 182,
      height: 35
    },
    logo_title: "FOOTBED FINDER",
    title_description: `
      <div>
        <div>
          Follow these steps to find your perfect SOLE footbed.
        </div>
        <div class="mt-3">
          Please note: all SOLE footbeds are custom moldable and suitable for any arch height.
        </div>
      </div>`,
    buttons_1: [
      {
        label: "Start",
        _styleClass: "mt-5"
      }
    ]
  };

  const shoe_fit = {
    _scene: "shoe_fit",
    _hideControls: ["skip", "close", "prev"],
    _progress: 30,
    _postRender: function(modalObj, footbedFinderInstance) {
      const nextBtn = modalObj.footer.querySelector("button.FF__next");
      if (!footbedFinderInstance.state.shoe_fit)
        nextBtn.setAttribute("disabled", 1);
      modalObj.body.addEventListener("click", function() {
        if (footbedFinderInstance.state.shoe_fit) {
          nextBtn.removeAttribute("disabled");
        }
      });
    },
    _styleClass: {
      body: "justify-content-center"
    },
    container: {
      _scene: "shoe_fit",
      question: `How do your shoes fit?`,
      options: {
        _styleClass: "mt-3",
        _type: "radio_button",
        thin: {
          id: 187,
          label: `Snug`,
          description:
            "Cleats, dress shoes, skates, ski and snowboard boots, and other footwear that fits on the tighter side."
        },
        medium: {
          id: 188,
          label: "Average",
          description:
            "Running shoes, casual sneakers, loafers, and other shoes that fit ‘regular’."
        },
        thick: {
          id: 189,
          label: `Roomy`,
          description:
            "Work boots, hiking boots, winter boots, and other footwear with some wiggle room to spare."
        }
      }
    }
  };

  const style = {
    _scene: "style",
    _hideControls: ["skip", "close"],
    _progress: 60,
    _postRender: function(modalObj, footbedFinderInstance) {
      const nextBtn = modalObj.footer.querySelector("button.FF__next");
      if (!footbedFinderInstance.state.style)
        nextBtn.setAttribute("disabled", 1);
      modalObj.body.addEventListener("click", function() {
        if (footbedFinderInstance.state.style) {
          nextBtn.removeAttribute("disabled");
        }
      });
    },
    _styleClass: {
      body: "justify-content-center"
    },
    container: {
      _scene: "style",
      question: `Select your footbed style.`,
      options: {
        _styleClass: "mt-3",
        _type: "radio_button",
        regular: {
          label: `Regular`,
          description: "Our original, most universal style."
        },
        wide: {
          id: 239,
          label: "Wide",
          description: "For wide/natural splay footwear."
        },
        met_pad: {
          id: 283,
          label: "Met Pad",
          description:
            "For pain/discomfort in the forefoot or balls of your feet, including Morton’s neuroma and metatarsalgia."
        }
      }
    }
  };

  const prefer_material = {
    _scene: "material",
    _hideControls: ["close", "skip", "next"],
    _progress: 100,
    _postRender: function(modalObj, footbedFinderInstance) {
      const finishBtn = modalObj.footer.querySelector("button.FF__finish");
      if (!footbedFinderInstance.state.material)
        finishBtn.setAttribute("disabled", 1);
      modalObj.body.addEventListener("click", function() {
        if (footbedFinderInstance.state.material) {
          finishBtn.removeAttribute("disabled");
        }
      });
    },
    _styleClass: {
      body: "justify-content-center"
    },
    container: {
      _scene: "material",
      question: `What material would you prefer?`,
      options: {
        _styleClass: "mt-3",
        _type: "radio_button",
        eva_base: {
          id: 539,
          label: "EVA plastic base",
          description:
            "Super durable, best for regularly swapping between shoes or wet conditions."
        },
        recycled: {
          id: 2,
          label: "Recycled cork base",
          description:
            "Better insulating, molds to your feet more quickly, sustainable natural material."
        }
      }
    }
  };

  const footbedfinder = new _filterBuilder(el, {
    prefix: "FF",
    id,
    effect: "fade-in",
    scenes: [start, shoe_fit, style, prefer_material],
    finish: goToProduct
  });

  return footbedfinder;
}

function goToProduct(footbedfinderObj) {
  const product = findProduct(footbedfinderObj);
  window.location = `/${window.settings.region}/shop/footbeds/${product.slug}`;
}

function getProductList(footbedfinderObj) {
  return footbedfinderObj.products_data.product_groups;
}

function findProduct(footbedfinderObj) {
  const products = getProductList(footbedfinderObj);
  const inputFromCustomer = footbedfinderObj.state;

  const foundProducts = products.filter((product) => {
    const options = {};

    Object.keys(inputFromCustomer).forEach((sceneName) => {
      options[sceneName] = footbedfinderObj.scenes.find(
        (scene) => scene._scene === sceneName
      ).container.options[inputFromCustomer[sceneName]].id;
    });

    const shoe_fit_check = options.shoe_fit
      ? product.meta_style_list.find(
        (styleObj) => Number(styleObj.id) === options.shoe_fit
      )
      : true;
    const style_check = options.style
      ? product.meta_style_list.find(
        (styleObj) => Number(styleObj.id) === options.style
      )
      : true;
    const material_check = options.material
      ? product.material_list.find(
        (materialObj) => Number(materialObj.id) === options.material
      )
      : true;
    return shoe_fit_check && style_check && material_check;
  });
  if (!foundProducts) return;

  const depriorities = ["webstoreDeals", "webstoreClearance"];

  const sortedProducts = foundProducts.sort((a, b) => {
    const aDeprioritized = a.catalog_tags.some(catalog => depriorities.includes(catalog));
    const bDeprioritized = b.catalog_tags.some(catalog => depriorities.includes(catalog));
    if (aDeprioritized && !bDeprioritized) return 1;
    if (bDeprioritized && !aDeprioritized) return -1;
    return 0;
  });
  return sortedProducts[0];
}

function setProductsData(footbedfinderInstance) {
  if (footbedfinderInstance.products_data) return;
  let region_plp_string = ["plp_data", window.settings.region]
    .filter((el) => el !== undefined)
    .join("_");
  if (localStorage && localStorage.getItem(region_plp_string)) {
    const data = JSON.parse(localStorage.getItem(region_plp_string));
    footbedfinderInstance.products_data = data.value;
  } else {
    axiosInstance.get(`/shop/api`).then(function(result) {
      footbedfinderInstance.products_data = result.data;
    });
  }
}

document.addEventListener("DOMContentLoaded", function() {
  const footbedfinder = render();
  window.footbedfinder = footbedfinder;
  setProductsData(footbedfinder);

  if (window.location.hash === "#footbed-finder") {
    window.hide_mailing_list_popup = 1; // hide mailing list sign up
    footbedfinder.showModal();
  }

  $(".footbed-finder-nav").on("click", function() {
    footbedfinder.showModal();
  });

  $("#footbed-finder-modal-popup").addClass(
    !window.settings && window.sole_config ? "ysdotcom" : "YourSole"
  );

  $('a[href="#footbed-finder"]').on("click", function() {
    footbedfinder.showModal();
  });

  $("#footbed-finder-modal-popup-content-modal").on(
    "hide.bs.modal",
    function() {
      let currentSlideIndex = window.footbedfinder.sceneIndex;
      let currentSlideName =
        window.footbedfinder.scenes[currentSlideIndex]._scene;
    }
  );
});
